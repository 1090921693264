<template>
    <div id="home-page" class="pt-3 mb-6">

        <div class="bg-info" id="homeDate">
            {{ currentDate }}
        </div>

        <!---Lien déclarer mon arrivée -->
        <router-link :to="declarationRoute" class="d-block d-lg-none mt-5">
            <b-card class="text-white form-group"
                    :class="{ 'bg-success': declarationSuccessClass, 'bg-danger': !declarationSuccessClass }">
                <b-card-body>
                    <h5 class="d-flex align-items-center justify-content-center m-0">
                        <span class="align-items-center d-flex"><i
                            class="fas fa-clock fa-2x  far mr-3"></i> {{ declarationButtonLabel }}</span>
                    </h5>
                </b-card-body>
            </b-card>
        </router-link>

        <router-link to="missions-disponibles" class="mt-1">
            <button class="btn bg-purple-corporate text-white form-group w-100 p-1" style="font-size: 1.3em">
                Missions disponibles
            </button>
        </router-link>

        <!--- prochaine intervention & notification -->
        <div class="row inter-notifs">
            <b-col sm="12" md="12" lg="12" xl="12" order="2" order-sm="2" order-md="2" order-lg="1" order-xl="1">
                <NextInterventionsList></NextInterventionsList>
            </b-col>
            <!--<b-col sm="12" md="12" lg="6" xl="6" order="1" order-sm="1" order-md="1" order-lg="2" order-xl="2" class="pr-3 pl-3">
                <NotificationsBanner></NotificationsBanner>
            </b-col>-->
        </div>

        <!--- heures déclarées & infos service -->
        <div class="row">
            <!--- heures déclarées -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <WorkDeclarationMonth></WorkDeclarationMonth>
            </div>

            <!--- infos services -->
            <!--<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <LastServicesInfos></LastServicesInfos>
            </div>-->
        </div>

        <WeekPlanning ref="weekPlanning" @weekPlanningSubmit="showNextPlanning"></WeekPlanning>
        <DisponibilitiesPlanning ref="disponibilities" @validation="setLocalStorageWeekModal"></DisponibilitiesPlanning>
    </div>
</template>

<script>
import NextInterventionsList from './NextInterventionsList'
// import NotificationsBanner from './NotificationsBanner'
import WorkDeclarationMonth from './WorkDeclarationMonth'
// import LastServicesInfos from './LastServicesInfos'
import WeekPlanning from './Modals/WeekPlanning'
import DisponibilitiesPlanning from './Modals/DisponibilitiesPlanning'
import store from '../../../store'
import pointageService from "../../../services/pointageService";
import Subscribe from '../../../subscribe'
import actions from '@/actions'
import userService from "@/services/userService";

export default {
    name: "home",
    components: {
        DisponibilitiesPlanning,
        WeekPlanning,
        // LastServicesInfos,
        WorkDeclarationMonth,
        NextInterventionsList,
        // NotificationsBanner
    },
    props: {
        actionName: {
            required: false,
        },
        actionData: {
            required: false,
            default: null
        },
        notifId: {
            required: false
        }
    },
    data() {
        return {
            currentDate: null,
            user: null,
            declarationRoute: {name: 'detection'},
            declarationButtonLabel: 'Déclarer mon arrivée',
            declarationSuccessClass: true
        }
    },
    mounted() {
        const action = actions[this.actionName];
        if (action) {
            action.handler(this.notifId, this.actionData);
            return this.$router.push({name: action.next ?? 'home'})
        }

        this.$emit('menu', 'home')
        this.$emit('checkVersion')
        this.currentDate = this.$moment().format('dddd D MMMM')
        if (!location.host.includes('family') && (!localStorage.getItem('weekModalDate') || this.$moment().isSameOrAfter(this.$moment(localStorage.getItem('weekModalDate')).day(8)))) {
            this.$refs.weekPlanning.show()
        }
        this.user = store.state.user;

        this.getNotifs();

        if (this.user.tracked) {
            document.dispatchEvent(new Event('init-hotjar'));
        }

        try {
            Subscribe.subscribe();
        } catch (e) {
            console.error(e);
        }

        pointageService.getPointageEnCours().then((response) => {
            if (response.data.etat === 'OK' && response.data.data.idintervention_ticking) {
                this.declarationRoute = {
                    name: 'intervention',
                    params: {idIntervention: response.data.data.idintervention_ticking}
                }
                this.declarationButtonLabel = 'Déclarer mon départ'
                this.declarationSuccessClass = false
            }
        }).catch((error) => {
            this.$rollbar.error('Erreur 3044', error);
            this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                title: 'Erreur 3044',
                autoHideDelay: 5000,
                variant: 'danger'
            })
        })
    },
    methods: {
        showNextPlanning() {
            this.$refs.disponibilities.show()
        },
        setLocalStorageWeekModal() {
            localStorage.setItem('weekModalDate', this.$moment().day(1).format('YYYY-MM-DD'))
        },
        getNotifs() {
            userService
                .getNotifs()
                .then(response => {
                    if (response.data.etat === 'OK') {
                        store.commit('UPDATE_NOTIF', response.data.data?.map(i => {
                            return {
                                ...i,
                                payload: JSON.parse(i.payload)
                            }
                        }))
                    } else {
                        this.$rollbar.error('Erreur 3071');
                        this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                            title: 'Erreur 3071',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                })
                .catch(err => {
                    this.$rollbar.error('Erreur 3072', err);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3072',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
#homeDate {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #homeDate {
        top: 80px;
    }
    .inter-notifs {
        margin-top: 55px;
    }
}
</style>
