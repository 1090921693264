<template>
	<div class="form-group">
		<h4 class="text-truncate" v-if="isGfs">Heures et kms déclarés ce mois</h4>
		<h4 class="text-truncate" v-else>Heures déclarées ce mois</h4>
		<div class="card mb-2 text-dark text-center">
			<span>
				Total : <b> {{ hours }}<template v-if="isGfs"> - {{ kms }} kms </template></b>
</span>
</div>
<div class="list-group text-dark">
	<div v-for="intervention in interventions" :key="intervention.idintervention_ticking">
		<router-link
			:to="{ name: 'fiche-client', params: { clientId: intervention.idcustomer, intervAdress: intervention.intervention_idadress } }"
			class="list-group-item list-group-item-action">
			<div v-if="!intervention.has_anomaly" class="centered-ico col-2 bg-green-alternative text-white"><i
					class="fa-2x fas fa-check" aria-hidden="true"></i></div>
			<div v-else class="centered-ico col-2 bg-warning text-white"><i class="fa-2x fas fa-search"
					aria-hidden="true"></i></div>
			<div class="align-items-center row text-dark">
				<div class="col-10 offset-2">
					<span class="d-block text-truncate">{{
			getFormattedTitle(intervention.customer_title)
		}} {{
				getFormattedFirstname(intervention.customer_firstname)
			}} {{ intervention.customer_lastname }}</span>
					<div v-if="intervention.end_time_real">
						<span class="d-block text-truncate">{{
			getTimeString(getDuration(intervention)) }}
							<template v-if="intervention.kms > 0">({{
			intervention.kms }}km)</template></span>
						<span class="d-block text-truncate text-muted">{{
			getFormattedDate(intervention.intervention_date)
		}} <br /> {{
				getFormattedHour(intervention.intervention_date,
					intervention.start_time_real)
			}} • {{
				getFormattedHour(intervention.intervention_date,
					intervention.end_time_real)
			}}</span>
						<span v-if="intervention.has_anomaly" class="text-warning">En cours de
							vérification</span>
						<span v-else class="text-success">Vérifié</span>
					</div>
					<div v-else>
						<span class="d-block text-truncate">En cours</span>
						<span class="d-block text-truncate text-muted">{{
			getFormattedDate(intervention.intervention_date)
							}}</span>
					</div>
				</div>
			</div>
		</router-link>
	</div>
</div>
<div class="text-center pt-2 d-none d-lg-block">
	<router-link to="/pointages"><u>Voir tout</u></router-link>
</div>
</div>
</template>

<script>
import pointageService from "../../../services/pointageService";

export default {
	name: "WorkDeclarationMonth",
	data() {
		return {
			interventions: [],
			kms: 0,
			hours: '0h'
		}
	},
	mounted() {
		this.getInterventions()
	},
	computed: {
		isGfs() {
			return location.host.includes('family');
		}
	},
	methods: {
		getInterventions() {
			pointageService.getHeuresDeclarees().then((response) => {
				if (response.data.etat === 'OK') {
					if (response.data.data) {
						response.data.data.forEach((item) => {
							if (!item.end_time_real)
								item.end_time_real = item.end_time_billable
						})
						response.data.data.sort((a, b) => {
							if (this.$moment(a.intervention_date + ' ' + a.start_time_real).isBefore(this.$moment(b.intervention_date + ' ' + b.start_time_real))) {
								return 1
							} else {
								return -1
							}
						})
						const hours = this.$moment();
						response.data.data.forEach(i => {
							this.kms += i.kms * 1;
							hours.add(this.getDuration(i))
						})
						this.hours = this.getTimeString(this.$moment.duration(hours.diff(this.$moment())), true)
						this.interventions = response.data.data.slice(0, 3)
					}
				} else {
					this.$rollbar.error('Erreur 3055');
					this.$bvToast.toast('Une erreur est survenue', {
						title: 'Erreur 3055',
						autoHideDelay: 5000,
						variant: 'danger'
					})
				}
			}).catch((error) => {
				this.$rollbar.error('Erreur 3056', error);
				this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
					title: 'Erreur 3056',
					autoHideDelay: 5000,
					variant: 'danger'
				})
			})
		},
		getTimeString(duration, short = false) {
			let hours = Math.floor(duration.asHours());
			let minutes = Math.ceil(duration.asMinutes() % 60);

			if (minutes === 60) {
				hours++;
				minutes = 0;
			}

			if (short)
				return `${hours} h ${minutes} m`;

			return `${hours} heure${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
		},
		getDuration(intervention) {
			const start = this.$moment(intervention.intervention_date + ' ' + intervention.start_time_real?.substring(0, 5))
			const end = this.$moment(intervention.intervention_date + ' ' + intervention.end_time_real?.substring(0, 5))
			if (end.format('HH:mm') === '00:00') end.add(1, 'day')

			return this.$moment.duration(this.$moment(end).diff(start));
		},
		getFormattedHour(date, hour) {
			return this.$moment(date + ' ' + hour).format('HH:mm')
		},
		getFormattedDate(date) {
			return this.$moment(date).format('dddd D MMMM')
		},
		getFormattedFirstname(firstname) {
			if (firstname === null)
				return ''

			return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase()
		},
		getFormattedTitle(title) {
			if (!title || title === 'null') return ''
			return title + '.'
		}
	}
}
</script>

<style scoped>

</style>
