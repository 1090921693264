import planningService from "@/services/planningService";
import userService from "@/services/userService";

export default {
    acceptSlotChange: {
        handler(idnotif, idslot) {
            userService.readNotif(idnotif);
            planningService.acceptSlotChange(idslot)
        },
        next: 'home'
    },
    rejectSlotChange: {
        handler(idnotif, idslot) {
            userService.readNotif(idnotif);
            planningService.rejectSlotChange(idslot)
        }
    },
}