import appService from "@/services/appService";

export default {
    async subscribe() {
        if (!window.navigator?.serviceWorker || !window.Notification) return;

        const config = {pushKey: process.env.VUE_APP_VAPID};

        const urlB64ToUint8Array = (base64String) => {
            const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, "+")
                .replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }

        const registrations = await window.navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
            if (registration.active?.scriptURL?.includes('/service-worker.js')) {
                await registration.unregister()
            }
        }

        const swReg = await window.navigator.serviceWorker.register("/service-worker.js");

        try {
            window.Notification.requestPermission()
                .then(async permission => {
                    console.log("permission", permission);
                    if (permission === 'granted') {
                        if (!swReg.active) {
                            await new Promise(resolve => {
                                setTimeout(resolve, 10000);
                            });
                        }

                        const subscription = await swReg.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlB64ToUint8Array(config.pushKey),
                        });

                        appService.subscribe({...JSON.parse(JSON.stringify(subscription)), user_agent: navigator.userAgent});
                    }
                });
        } catch (e) {
            console.error(e);
        }
    }
}

