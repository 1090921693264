<template>
    <b-modal ref="weekPlanning" id="validationCreneauxModal" no-close-on-backdrop no-close-on-esc>
        <template v-slot:modal-header>
            <div class="modal-title text-center w-100 bg-info text-white p-3">
                <span class="font-weight-bold m-0 h4">Votre planning de la semaine</span><br/>
                Merci de vérifier vos créneaux.<br/>En cas d'empêchement l'équipe RH sera avertie :
            </div>
        </template>
        <template v-slot:default>
            <b-container fluid>
                <div class="text-center" v-if="load">
                    <b-spinner type="grow" variant="danger" class="mt-5 mb-5"></b-spinner>
                </div>
                <b-list-group id="listeCreneauxTemplate" class="mb-2" v-if="!load">
                    <div v-for="day in interventions" :key="day.date">
                        <b-row class="day mt-2"> {{ getFormattedDate(day.date) }}</b-row>
                        <b-list-group-item v-for="intervention in day.interventions" :key="intervention.idagendas_slot">
                            <b-row class="pl-1 pr-1">
                                <div style="width: 80%">
                                    {{ getFormattedHour(day.date, intervention.start_time) }} <br/>
                                    {{ intervention.customer_firstname }} {{ intervention.customer_lastname }} <br/>
                                    {{ intervention.adress1 }} <br/>
                                    {{ intervention.postal_code }}, {{ intervention.city }} <br/>
                                </div>
                                <div class="toggleZone"
                                     @click="addMotifAnnulation(intervention.idagenda_slot, intervention.value)">
                                    <toggle-button
                                        v-if="today != day.date"
                                        :color="{  unchecked: '#DC3545', checked: '#28A745' }"
                                        :switch-color="{ checked: '#FFF' }"
                                        :width="30"
                                        :height="16"
                                        v-model="intervention.value"></toggle-button>
                                    <toggle-button
                                        v-else
                                        :color="{  unchecked: '#CECECE', checked: '#CECECE' }"
                                        :width="30"
                                        :height="16"
                                        v-model="intervention.value"></toggle-button>
                                </div>
                                <div v-if="!intervention.value" style="display: block; margin: 0 auto;"
                                     class="text-center mb-2 mt-2">
                                    <div v-if="today != day.date">
                                        <label for="motif-annulation">Demande d'annulation:</label><br>
                                        <select id="motif-annulation"
                                                v-model="interventionsAnnulation[intervention.idagenda_slot]">
                                            <option value="employee">intervenant(e)</option>
                                            <option value="customer">client(e)</option>
                                        </select>
                                    </div>
                                    <div v-else>
                                        Annulation impossible le jour même, <br> merci de nous contacter par téléphone.
                                    </div>
                                </div>
                            </b-row>
                        </b-list-group-item>
                    </div>
                </b-list-group>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <button type="submit" class="btn btn-lg btn-success w-100 m-0 no-top-border-radius" @click="validate">
                <span><i class="far fa-calendar-alt mr-2"></i>Je valide le planning</span>
            </button>
        </template>
    </b-modal>
</template>

<script>
import planningService from '../../../../services/planningService'
import store from '../../../../store'

export default {
    name: "WeekPlanning",
    data() {
        return {
            currentDate: null,
            user: null,
            load: true,
            interventions: [],
            interventionsAnnulation: {},
            today: null
        }
    },
    mounted() {
        this.today = this.$moment().format('YYYY-MM-DD')
        this.currentDate = this.$moment().format('dddd D MMMM')
        this.user = store.state.user
        this.getPlanning()
    },
    methods: {
        getFormattedHour(date, hour) {
            return this.$moment(date + ' ' + hour).format('HH:mm')
        },
        show() {
            this.$refs.weekPlanning.show()
        },
        addMotifAnnulation(idagenda_slot, value) {
            if (value) {
                this.interventionsAnnulation[idagenda_slot] = "employee"
            } else {
                delete this.interventionsAnnulation[idagenda_slot]
            }
        },
        validate(event) {
            event.preventDefault()
            let idagendas_slot = '';
            let annulations = '';
            let ids_employee = '';
            this.load = true;
            this.interventions.forEach((day) => {
                day.interventions.forEach((intervention) => {
                    if (intervention.value === false) {
                        if (idagendas_slot === '') {
                            idagendas_slot += intervention.idagenda_slot;
                            annulations += this.interventionsAnnulation[intervention.idagenda_slot];
                            ids_employee += intervention.idemployee;
                        } else {
                            idagendas_slot += ',' + intervention.idagenda_slot
                            annulations += ',' + this.interventionsAnnulation[intervention.idagenda_slot]
                            ids_employee += ',' + intervention.idemployee;
                        }
                    }
                })
            })
            if (idagendas_slot !== '') {
                planningService.cancelPlanning({
                    idagendas_slot: idagendas_slot,
                    annulations: annulations,
                    ids_employee: ids_employee
                }).then((response) => {
                    if (response.data.etat === 'OK') {
                        this.$refs.weekPlanning.hide()
                        this.$emit('weekPlanningSubmit')
                    } else {
                        this.$rollbar.error('Erreur 3049');
                        this.$bvToast.toast('Une erreur est survenue', {
                            title: 'Erreur 3049',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$rollbar.error('Erreur 3050', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3050',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                })
            } else {
                this.$refs.weekPlanning.hide()
                this.$emit('weekPlanningSubmit')
            }
        },
        getFormattedDate(date) {
            return this.$moment(date).format('dddd D MMMM')
        },
        getPlanning() {
            planningService.getPlanning(null, null).then((response) => {
                if (response.data.etat === 'OK') {
                    if (response.data.data) {
                        let date_now = new Date();
                        response.data.data.forEach((intervention) => {
                            let date_inter = new Date(intervention.date_slot)
                            let inter_is_finish = false
                            //On affiche pas les interventions des journées précedentes
                            if ((date_inter.getMonth() < date_now.getMonth()) ||
                                ((date_inter.getDate() < date_now.getDate()) && date_inter.getMonth() === date_now.getMonth())
                            ) {
                                inter_is_finish = true
                            }
                            //On affiche pas les interventions de la journée qui sont déjà passé
                            if (date_inter.getDate() === date_now.getDate()
                                && date_inter.getMonth() === date_now.getMonth()
                                && date_inter.getFullYear() === date_now.getFullYear()) {
                                let date_debut = intervention.start_time.split(':');
                                if (date_now.getHours() > parseInt(date_debut[0])) {
                                    inter_is_finish = true
                                }
                            }

                            if (!inter_is_finish) {
                                let item = this.interventions.find(i => i.date === intervention.date_slot)
                                if (item === undefined) {
                                    this.interventions.push({
                                        date: intervention.date_slot,
                                        interventions: []
                                    })
                                    item = this.interventions.find(i => i.date === intervention.date_slot)
                                }
                                intervention['value'] = true
                                item.interventions.push(intervention)
                                item.interventions.sort((a, b) => {
                                    if (this.$moment(a.date_slot + ' ' + a.start_time).isBefore(this.$moment(b.date_slot + ' ' + b.start_time))) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                })
                            }
                        })
                        this.interventions.sort((a, b) => {
                            if (this.$moment(a.date).isBefore(this.$moment(b.date))) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                    }
                    this.load = false
                } else {
                    this.$rollbar.error('Erreur 3051');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3051',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3052', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3052',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
                this.load = false
            })
        }
    }
}
</script>

<style scoped>
/deep/ #validationCreneauxModal .modal-header,
/deep/ #validationCreneauxModal .modal-footer,
/deep/ #validationCreneauxModal .container-fluid {
    padding: 0;
}

.toggleZone {
    width: 20%;
    position: relative;
}

.toggleZone label {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 15px);
}

.day {
    padding: 15px;
    font-weight: bold;
    font-size: 20px;
}
</style>
