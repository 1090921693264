<template>
    <b-modal ref="disponibilitiesPlanning" id="plagesHorairesLibresModal" no-close-on-backdrop no-close-on-esc>
        <template v-slot:modal-header>
            <div class="modal-title text-center w-100 bg-info text-white p-3">
                <span class="font-weight-bold m-0 h4">Vos disponibilités</span><br />
                Plages horaires libres
            </div>
        </template>
        <template v-slot:default>
            <b-container fluid>
                <h6 class="m-0 pt-3 text-center">Nous avons des clients en attente de prestation dans votre zone, avez-vous d'autres disponibilités ?</h6>
                <h6 class="m-0 pt-1 pb-3 ">Sélectionnez les demi-journées disponibles.</h6>
                <b-list-group id="calendrierDemiJourneeTemplate">
                    <b-row v-for="workDay in week" :key="workDay.day" class="">
                        <div class="week-item day">
                            <div>{{ workDay.day }}</div>
                        </div>
                        <div class="week-item">
                            <button @click="changeValue(workDay, 'am')" :class="[{selected: workDay.am.value && !workDay['am'].locked}, [{checked: workDay.am.value }, '']]">
                                Matin <span v-if="!workDay.am.value">libre</span>
                            </button>
                        </div>
                        <div class="week-item">
                            <button @click="changeValue(workDay, 'pm')" :class="[{selected: workDay.pm.value && !workDay['pm'].locked}, [{checked: workDay.pm.value }, '']]">
                                Après-midi <span v-if="!workDay.pm.value">libre</span>
                            </button>
                        </div>
                    </b-row>
                </b-list-group>
                <b-row>
                    <b-col sm="12">Vous avez sélectionné :</b-col>
                    <b-col sm="12">
                        <span v-for="(segment, index) in selectedSegment" :key="index" class="font-weight-bold">
                            {{ segment }}<span v-if="(index + 1) < selectedSegment.length">, </span>
                        </span>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template v-slot:modal-footer>
            <button type="submit" class="btn btn-lg btn-success w-100 m-0 no-top-border-radius" @click="validate">
                <span><i class="far fa-calendar-alt mr-2"></i>Je valide le planning</span>
            </button>
        </template>
    </b-modal>
</template>

<script>
import planningService from '../../../../services/planningService'
import store from "../../../../store";

export default {
    name: "DisponibilitiesPlanning",
    data() {
        return {
            week: [
                {
                    id: 1,
                    day: 'Lundi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 2,
                    day: 'Mardi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 3,
                    day: 'Mercredi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 4,
                    day: 'Jeudi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 5,
                    day: 'Vendredi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 6,
                    day: 'Samedi',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                },
                {
                    id: 0,
                    day: 'Dimanche',
                    am: {
                        locked: false,
                        value: false
                    },
                    pm: {
                        locked: false,
                        value: false
                    }
                }
            ],
            selectedSegment: [],
            user: null,
            dontShow: false
        }
        },
        mounted () {
            this.user = store.state.user
            this.getWorkingPlanning()
        },
        methods: {
            show () {
                if (this.dontShow) {
                    this.$refs.disponibilitiesPlanning.hide()
                    this.$emit('validation')
                } else {
                    this.$refs.disponibilitiesPlanning.show()
                }
            },
            validate (event) {
                this.$refs.disponibilitiesPlanning.hide()

                event.preventDefault()
                let workdays = ''
                this.week.forEach((item) => {
                    let data = (!item.am.locked && item.am.value) ? 'matin' : ''
                    data += (!item.pm.locked && item.pm.value) ? (((data !== '') ? ',' : '') + 'apresmidi') : ''
                    if (data !== '') {
                        workdays += ((workdays !== '') ? '|' : '') + String(item.id) + ':' + data
                    }
                })

                if (workdays !== '') {
                    planningService.addWorkingDays({workdays: workdays}).then((response) => {
                        if (response.data.etat === 'OK') {
                            this.$refs.disponibilitiesPlanning.hide()
                            this.$emit('validation')
                        } else {
                            this.getWorkingPlanning()
                            this.$refs.disponibilitiesPlanning.hide()
                            this.$rollbar.error('Erreur 3045');
                            this.$bvToast.toast('Une erreur est survenue. Veuillez ré-essayer.', {
                                title: 'Erreur 3045',
                                autoHideDelay: 4000,
                                variant: 'danger'
                            })
                            setTimeout(() => {
                                this.show()
                            }, 4000)
                        }
                    }).catch((error) => {
                        this.$rollbar.error('Erreur 3046', error);
                        this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                            title: 'Erreur 3046',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    })
                } else {
                    this.$refs.disponibilitiesPlanning.hide();
                    this.$emit('validation');
                }
            },
            changeValue (dayWeek, type) {
                if (!dayWeek[type].locked) {
                    dayWeek[type].value = !dayWeek[type].value
                    let label = dayWeek.day + ((type === 'am') ? ' Matin' : ' Après-midi')
                    if (dayWeek[type].value) {
                        this.selectedSegment.push(label)
                    } else {
                        this.selectedSegment.splice(this.selectedSegment.indexOf(label), 1)
                    }
                }
            },
            getWorkingPlanning () {
                planningService.getWorkingPlanning().then((response) => {
                    if (response.data.etat === 'OK') {
                        Object.keys(response.data.data).forEach((day) => {
                            let selectedDay = this.week.find(i => i.id === parseInt(day))
                            selectedDay.am.locked = selectedDay.am.value = response.data.data[day].matin
                            selectedDay.pm.locked = selectedDay.pm.value = response.data.data[day].apresmidi
                        });

                        const selectedHalfDays = this.week.reduce((acc, day) => {
                            //compte des demi-journées sauf le dimanche
                            return acc + (day.am.value * 1 + day.pm.value * 1) * (day.id != 0);
                        }, 0);

                        //le dimanche n'est pas compté, donc -2 demi journées
                        if (selectedHalfDays == this.week.length * 2 - 2) {
                            this.dontShow = true;
                        }

                    } else {
                        this.$rollbar.error('Erreur 3047');
                        this.$bvToast.toast('Une erreur est survenue', {
                            title: 'Erreur 3047',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$rollbar.error('Erreur 3048', error);
                    this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                        title: 'Erreur 3048',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                })
            }
        }
    }
</script>

<style scoped>
    /deep/ #plagesHorairesLibresModal .modal-header,
    /deep/ #plagesHorairesLibresModal .modal-footer,
    /deep/ #plagesHorairesLibresModal .container-fluid {
        padding: 0;
    }
    .toggleZone {
        width: 20%;
        position: relative;
    }
    .toggleZone label {
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 15px);
    }
    .week-item.day {
        position: relative;
    }
    .week-item.day div {
        position: absolute;
        font-weight: bold;
        font-size: 20px;
        top: calc(50% - 15px);
        left: 15px;
    }
    .week-item {
        width: 33%;
        margin-bottom: 10px;
        text-align: center;
    }
    .week-item button {
        border: #000 1px solid;
        border-radius: 5px;
        width: 85px;
        height: 85px;
        background: #ffffff;
    }
    .week-item button.checked {
        background: #919191;
        color: #FFF;
    }

    .week-item button.selected {
        background: #1b5d1b;
        color: #FFF;
    }
</style>
