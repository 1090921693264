<template>
    <!--- liste prochaine intervention -->
    <b-form-group>
        <h4>Vos prochaines interventions</h4>
        <b-list-group class="text-dark listIntervention restrict-list-to-3-divs-items" v-if="interventions.length > 0">
            <router-link :to="{ name: 'fiche-client', params: { clientId: intervention.customer_idcustomer, intervAdress: intervention.intervention_idadress } }"
                class="list-group-item list-group-item-action" v-for="intervention in interventions"
                :class="{'zebra': intervention.canceled_category}" :key="intervention.idagenda_slot">
                <div class="centered-ico col-2 bg-info-corporate"><i class="fa-2x fas fa-lightbulb"
                        aria-hidden="true"></i>
                </div>
                <div class="align-items-center row text-dark">
                    <div class="col-10 offset-2">
                        <span class="d-block text-truncate">{{
                        getFormattedTitle( intervention.customer_title)
                        }} {{
                            getFormattedFirstname(intervention.customer_firstname)
                            }} {{ intervention.customer_lastname }}</span>
                        <span class="d-block text-truncate">{{ getNbHours(intervention) }} heures</span>
                        <span class="d-block text-truncate text-muted">{{
                        getFormattedDate(intervention.date_slot)
                        }} <br />{{
                            getFormattedHour(intervention.date_slot, intervention.start_time)
                            }} • {{ getFormattedHour(intervention.date_slot, intervention.end_time) }}</span>
                        <div v-if="intervention.customer_instructions">
                            <strong>Consigne: </strong>{{ intervention.customer_instructions }}
                        </div>
                        <div v-if="intervention.canceled_category">
                            <strong>Prestation annulée</strong>
                        </div>
                    </div>
                </div>
            </router-link>
        </b-list-group>
        <!--- notification -->
        <div class="card bg-dark text-white form-group" v-else>
            <div class="card-body">
                <h5 class="d-flex align-items-center justify-content-center m-0">
                    Aucun rendez-vous ou prestation de prévu aujourd’hui.
                </h5>
            </div>
        </div>
        <!--<div class="text-center pt-2">
            <router-link to="/agenda"><u>Voir tout</u></router-link>
        </div>-->
    </b-form-group>
</template>

<script>
import planningService from '../../../services/planningService'

export default {
    name: "NextInterventionsList",
    data() {
        return {
            interventions: []
        }
    },
    methods: {
        getNbHours(intervention) {
            let nb = this.$moment.duration(
                this.$moment(intervention.date_slot + ' ' + intervention.end_time).diff(
                    this.$moment(intervention.date_slot + ' ' + intervention.start_time)
                )
            ).toISOString().replace('PT', '').replace('M', '')
            nb = nb.split('H')
            if (nb[1] == "")
                nb = nb[0]
            else
                nb = nb[0] + ':' + nb[1]
            return nb
        },
        getFormattedHour(date, hour) {
            return this.$moment(date + ' ' + hour).format('HH:mm')
        },
        getFormattedDate(date) {
            return this.$moment(date).format('dddd D MMMM')
        },
        getNextInterventions() {
            planningService.getPlanning(this.$moment().format('YYYY-MM-DD'), null).then((response) => {
                if (response.data.etat === 'OK') {
                    if (response.data.data) {
                        //On supprime les interventions de la journée qui sont déjà passé
                        let inter_finish = [];
                        for (let i = 0; i < response.data.data.length; i++) {
                            let item = response.data.data[i];
                            let date_now = new Date()
                            let date_inter = new Date(item.date_slot)
                            if (date_inter.getDate() === date_now.getDate()) {
                                let date_debut = item.start_time.split(':');
                                if (date_now.getHours() > parseInt(date_debut[0])) {
                                    inter_finish.push(item)
                                }
                            }
                        }
                        inter_finish.forEach(item => response.data.data.splice(response.data.data.indexOf(item), 1))

                        this.interventions = response.data.data.sort((a, b) => {
                            if (this.$moment(a.date_slot + ' ' + a.start_time).isBefore(this.$moment(b.date_slot + ' ' + b.start_time))) {
                                return -1
                            } else {
                                return 1
                            }
                        }).slice(0, 3)
                    }
                } else {
                    this.$rollbar.error('Erreur 3053');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3053',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3054', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3054',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getFormattedFirstname(firstname) {
            if (firstname === null)
                return ''

            return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase()
        },
        getFormattedTitle(title) {
            if (!title || title === 'null') return ''
            return title + '.'
        }
    },
    mounted() {
        this.getNextInterventions()
    }
}
</script>

<style scoped>
.zebra {
    background: repeating-linear-gradient(45deg, #cbf0ff, #cbf0ff 10px, #ffffff 10px, #ffffff 20px)
}
</style>
